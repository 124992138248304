














































































import { ValidationProvider } from 'vee-validate/dist/vee-validate.full.esm'
import { Component, Vue } from 'vue-property-decorator'
import InputNumber from '@/components/Controls/InputNumber.vue'
import InputText from '@/components/Controls/InputText.vue'
import Calendar from '@/components/Controls/Calendar.vue'
import { componentsImports } from '../FormFields/index'

@Component({
  components: {
    ...componentsImports(),
    ValidationProvider,
    InputNumber,
    InputText,
    Calendar
  }
})
export default class IndustrialWarehouse extends Vue {
  formData () {
    return {
      UseDescription: this.useDescription,
      GeneralCondition: this.generalCondition,
      ApproximateBuildYear: this.approximateBuildYear,
      FloorArea: this.totalFloorArea,
      WarehouseFloorArea: this.warehouseFloorArea,
      OfficeFloorArea: this.officeFloorArea,
      OtherFloorAreas: this.otherFloorAreas,
      DesignatedCarParks: this.carParks,
      FloorConstruction: this.floorConstruction,
      WallConstruction: this.wallConstruction,
      RoofConstruction: this.roofConstruction,
      AnnualNetRental: this.annualNetRental,
      AnnualGrossRental: this.annualGrossRental,
      AnnualOutgoings: this.annualOutgoings,
      AccommodationFeatures: this.accommodationFeatures,
      PropertyFeatures: this.propertyFeatures,
      EstimatedValue: this.estimatedValue
    }
  }

  loadData (data: any) {
    this.useDescription = data.UseDescription
    this.generalCondition = data.GeneralCondition
    this.approximateBuildYear = data.ApproximateBuildYear
    this.totalFloorArea = data.FloorArea
    this.warehouseFloorArea = data.WarehouseFloorArea
    this.officeFloorArea = data.OfficeFloorArea
    this.otherFloorAreas = data.OtherFloorAreas
    this.carParks = data.DesignatedCarParks
    this.floorConstruction = data.FloorConstruction
    this.wallConstruction = data.WallConstruction
    this.roofConstruction = data.RoofConstruction
    this.annualNetRental = data.AnnualNetRental
    this.annualGrossRental = data.AnnualGrossRental
    this.annualOutgoings = data.AnnualOutgoings
    this.accommodationFeatures = data.AccommodationFeatures
    this.propertyFeatures = data.PropertyFeatures
    this.estimatedValue = data.EstimatedValue
  }

  propertyType () {
    return 'Industrial'
  }

  useDescription: any = null
  generalCondition: any = null
  approximateBuildYear: any = null
  totalFloorArea: any = null
  warehouseFloorArea: any = null
  officeFloorArea: any = null
  otherFloorAreas: any = null
  carParks: any = null
  floorConstruction: any = null
  wallConstruction: any = null
  roofConstruction: any = null
  annualNetRental: any = null
  annualGrossRental: any = null
  annualOutgoings: any = null
  estimatedValue: any = null
  accommodationFeatures: string[] = []
  propertyFeatures: string[] = []
}
