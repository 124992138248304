var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('form',{staticClass:"step-field-form"},[_c('h3',{staticClass:"step-section-title"},[_vm._v("Warehouse")]),_c('ValidationProvider',{attrs:{"name":"Use Description","rules":""},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('InputText',{attrs:{"fieldId":"useDescription","error":errors[0],"placeholder":"e.g. Distribution warehouse, Industrial Store or Showroom","label":"Use Description"},model:{value:(_vm.useDescription),callback:function ($$v) {_vm.useDescription=$$v},expression:"useDescription"}})]}}])}),_c('GeneralCondition',{model:{value:(_vm.generalCondition),callback:function ($$v) {_vm.generalCondition=$$v},expression:"generalCondition"}}),_c('ValidationProvider',{attrs:{"name":"Approximate Building Year","rules":""},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('Calendar',{attrs:{"fieldId":"approximateBuildYear","error":errors[0],"placeholder":"Select approx build year","view":"year","label":"Approximate Building Year"},model:{value:(_vm.approximateBuildYear),callback:function ($$v) {_vm.approximateBuildYear=$$v},expression:"approximateBuildYear"}})]}}])}),_c('ValidationProvider',{attrs:{"name":"Total Floor Area (m²)","rules":"required|numeric"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('InputNumber',{attrs:{"fieldId":"totalFloorArea","error":errors[0],"placeholder":"Enter total floor area","suffix":"m²","label":"Total Floor Area (m²)"},model:{value:(_vm.totalFloorArea),callback:function ($$v) {_vm.totalFloorArea=$$v},expression:"totalFloorArea"}})]}}])}),_c('ValidationProvider',{attrs:{"name":"Warehouse Floor Area approx. (m²)","rules":"numeric"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('InputNumber',{attrs:{"fieldId":"warehouseFloorArea","error":errors[0],"placeholder":"Enter warehouse floor area","suffix":"m²","label":"Warehouse Floor Area approx. (m²)"},model:{value:(_vm.warehouseFloorArea),callback:function ($$v) {_vm.warehouseFloorArea=$$v},expression:"warehouseFloorArea"}})]}}])}),_c('ValidationProvider',{attrs:{"name":"Office Floor Area approx. (m²)","rules":"numeric"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('InputNumber',{attrs:{"fieldId":"officeFloorArea","error":errors[0],"placeholder":"Enter office floor area","suffix":"m²","label":"Office Floor Area approx. (m²)"},model:{value:(_vm.officeFloorArea),callback:function ($$v) {_vm.officeFloorArea=$$v},expression:"officeFloorArea"}})]}}])}),_c('ValidationProvider',{attrs:{"name":"Other Floor Areas (m²)","rules":"numeric"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('InputNumber',{attrs:{"fieldId":"otherFloorAreas","error":errors[0],"placeholder":"Enter other floor areas","suffix":"m²","label":"Other Floor Areas (m²)"},model:{value:(_vm.otherFloorAreas),callback:function ($$v) {_vm.otherFloorAreas=$$v},expression:"otherFloorAreas"}})]}}])}),_c('ValidationProvider',{attrs:{"name":"Designated Car Parks","rules":"numeric"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('InputNumber',{attrs:{"fieldId":"carParks","error":errors[0],"showButtons":true,"placeholder":"Enter number of car parks","label":"Designated Car Parks"},model:{value:(_vm.carParks),callback:function ($$v) {_vm.carParks=$$v},expression:"carParks"}})]}}])}),_c('h3',{staticClass:"step-section-title"},[_vm._v("Construction Details")]),_c('FloorConstruction',{model:{value:(_vm.floorConstruction),callback:function ($$v) {_vm.floorConstruction=$$v},expression:"floorConstruction"}}),_c('WallConstruction',{model:{value:(_vm.wallConstruction),callback:function ($$v) {_vm.wallConstruction=$$v},expression:"wallConstruction"}}),_c('RoofConstruction',{model:{value:(_vm.roofConstruction),callback:function ($$v) {_vm.roofConstruction=$$v},expression:"roofConstruction"}}),_c('h3',{staticClass:"step-section-title"},[_vm._v("Property Income")]),_c('ValidationProvider',{attrs:{"name":"Current Annual Net Rental","rules":"numeric"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('InputNumber',{attrs:{"fieldId":"annualNetRental","error":errors[0],"placeholder":"Enter annual net rental","mode":"currency","currency":"AUD","label":"Current Annual Net Rental"},model:{value:(_vm.annualNetRental),callback:function ($$v) {_vm.annualNetRental=$$v},expression:"annualNetRental"}})]}}])}),_c('ValidationProvider',{attrs:{"name":"Current Annual Gross Rental","rules":"numeric"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('InputNumber',{attrs:{"fieldId":"annualGrossRental","error":errors[0],"placeholder":"Enter annual gross rental","mode":"currency","currency":"AUD","label":"Current Annual Gross Rental"},model:{value:(_vm.annualGrossRental),callback:function ($$v) {_vm.annualGrossRental=$$v},expression:"annualGrossRental"}})]}}])}),_c('ValidationProvider',{attrs:{"name":"Current Annual Outgoings","rules":"numeric"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('InputNumber',{attrs:{"fieldId":"annualOutgoings","error":errors[0],"placeholder":"Enter annual outgoings","mode":"currency","currency":"AUD","label":"Current Annual Outgoings"},model:{value:(_vm.annualOutgoings),callback:function ($$v) {_vm.annualOutgoings=$$v},expression:"annualOutgoings"}})]}}])}),_c('h3',{staticClass:"step-section-title"},[_vm._v("Accommodation Features")]),_c('AccommodationFeatures',{attrs:{"featureType":"Industrial"},model:{value:(_vm.accommodationFeatures),callback:function ($$v) {_vm.accommodationFeatures=$$v},expression:"accommodationFeatures"}}),_c('h3',{staticClass:"step-section-title"},[_vm._v("Property Features")]),_c('PropertyFeatures',{attrs:{"featureType":"Industrial"},model:{value:(_vm.propertyFeatures),callback:function ($$v) {_vm.propertyFeatures=$$v},expression:"propertyFeatures"}}),_c('ValidationProvider',{attrs:{"name":"Estimated Value / Current Book Value","rules":"numeric"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('InputNumber',{attrs:{"fieldId":"estimatedValue","error":errors[0],"placeholder":"Enter estimated value","mode":"currency","currency":"AUD","label":"Estimated Value / Current Book Value"},model:{value:(_vm.estimatedValue),callback:function ($$v) {_vm.estimatedValue=$$v},expression:"estimatedValue"}})]}}])})],1)}
var staticRenderFns = []

export { render, staticRenderFns }