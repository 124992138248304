import { render, staticRenderFns } from "./IndustrialWarehouse.vue?vue&type=template&id=4b073828&scoped=true&"
import script from "./IndustrialWarehouse.vue?vue&type=script&lang=ts&"
export * from "./IndustrialWarehouse.vue?vue&type=script&lang=ts&"
import style0 from "./IndustrialWarehouse.vue?vue&type=style&index=0&id=4b073828&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "4b073828",
  null
  
)

export default component.exports